.gc {
  .gc-relative {
    position: relative;
  }

  .gc-fixed {
    position: fixed;
  }

  &.gc-loading {
    .gc-interaction {
      display: none;
    }

    .gc-control {
      display: none;
    }

    .gc-tooltip {
      display: none;
    }
  }
}

.gc-overlay {
  position: fixed;
  z-index: 6400;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: content-box;
  opacity: 0.4;
  pointer-events: none;

  & svg {
    fill: #222222;
    width: 100%;
    height: 100%;

    & path {
      pointer-events: fill;
    }
  }
}

.gc-control {
  position: absolute;
  z-index: 6404;
  visibility: hidden;
  box-sizing: content-box;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background-color: transparent;
}

.gc-interaction {
  position: absolute;

  &.gc-disable {
    position: absolute;
    cursor: inherit;
    z-index: 6403;
    opacity: 0;
    background-color: white;
    filter: alpha(opacity=0);
  }
}

.gc-tooltip {
  position: absolute;
  visibility: visible;
  box-sizing: content-box;
  min-width: 300px;
  max-width: 550px;
  padding: 25px;
  -moz-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, .4);

  &[data-guidechimp-position='top'] {
    margin-bottom: 10px;
  }

  &[data-guidechimp-position='left'] {
    margin: 0 10px 0 10px;
  }

  &[data-guidechimp-position='right'] {
    margin: 0 10px 0 10px;
  }

  &[data-guidechimp-position='bottom'] {
    margin-top: 10px;
  }

  &[data-guidechimp-alignment='right'] {
    margin-left: 5px;
  }

  &[data-guidechimp-alignment='left'] {
    margin-right: 5px;
  }
}

.gc-tooltip-tail {
  position: absolute;
  content: '';
  border: 6px solid transparent;

  .gc-tooltip[data-guidechimp-position='top'] & {
    bottom: -10px;
    border-top-color: white;
  }

  .gc-tooltip[data-guidechimp-position='top'][data-guidechimp-alignment='right'] & {
    right: 10px;
    bottom: -10px;
    border-top-color: white;
  }

  .gc-tooltip[data-guidechimp-position='top'][data-guidechimp-alignment='middle'] & {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: white;
  }

  .gc-tooltip[data-guidechimp-position='left'] & {
    top: 10px;
    right: -10px;
    border-left-color: white;
  }

  .gc-tooltip[data-guidechimp-position='right'] & {
    top: 10px;
    left: -10px;
    border-right-color: white;
  }

  .gc-tooltip[data-guidechimp-position='bottom'] & {
    top: -10px;
    border-bottom-color: white;
  }

  .gc-tooltip[data-guidechimp-position='bottom'][data-guidechimp-alignment='right'] & {
    top: -10px;
    right: 10px;
    border-bottom-color: white;
  }

  .gc-tooltip[data-guidechimp-position='bottom'][data-guidechimp-alignment='middle'] & {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: white;
  }
}

.gc-close {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  overflow: hidden;
  width: 15px;
  height: 15px;
  margin: 10px;
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }

  &::before, &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    content: '';
    border-radius: 5px;
    background: #000;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.gc-progressbar {
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  box-sizing: content-box;
  width: 0;
  height: 5px;
  text-align: center;
  border-radius: 2px 0 0 0;
  background-color: $primary;

  &[aria-valuenow='100'] {
    border-radius: 2px 2px 0 0;
  }

  .gc-tooltip[data-guidechimp-position='bottom'] & {
    top: auto;
    bottom: 0;
    border-radius: 0 0 0 2px;

    &[aria-valuenow='100'] {
      border-radius: 0 0 2px 2px;
    }
  }

  &.gc-hidden {
    display: none;
  }
}

.gc-fake-step {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}

.gc-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 5px 0 5px;

  &.gc-hidden {
    display: none;
  }
}

.gc-description {
  font-size: 14px;
  overflow-y: auto;
  max-height: 200px;
  margin-bottom: 20px;
  padding: 0 5px 0 5px;
  text-align: justify;
  scroll-behavior: smooth;

  &.gc-hidden {
    display: none;
  }
}

.gc-custom-buttons {
  margin-bottom: 20px;

  &.gc-hidden {
    display: none;
  }
}

.gc-navigation {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 10px 10px;

  &.gc-hidden {
    display: none;
  }
}

.gc-navigation-prev,
.gc-navigation-next {
  width: 15px;
  height: 15px;
  cursor: pointer;
  border-right: 5px solid $secondary;
  border-bottom: 5px solid $secondary;

  &:hover {
    opacity: 1;
    border-color: $primary;
  }

  &.gc-hidden {
    visibility: hidden;
  }
}

.gc-navigation-prev {
  transform: rotate(-225deg);
}

.gc-navigation-next {
  transform: rotate(-45deg);
}

.gc-pagination {
  &.gc-hidden {
    visibility: hidden;
  }
}

.gc-pagination-theme-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  & .gc-pagination-item,
  & .gc-pagination-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 5px;
    font-size: 14px;
    cursor: pointer;
  }

  & .gc-pagination-dots {
    width: 20px;
    height: 20px;
    color: $primary;
    cursor: initial;
  }

  & .gc-pagination-item {
    width: 20px;
    height: 20px;
    border-radius: 2px;

    &:hover {
      color: #ffffff;
      background: $primary;
    }

    &.gc-pagination-active {
      color: #ffffff;
      background: $primary;
    }
  }
}

.gc-pagination-theme-circles {
  & .gc-pagination-item{
    background-color: $secondary;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 8px;
    margin: 0 5px;
    padding: 0;
    width: 8px;

    &:hover {
      background-color: $primary;
    }

    &.gc-pagination-active {
      background-color: $primary;
    }
  }
}

.gc-copyright {
  font-family: Arial, serif;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 5px 10px;
  letter-spacing: 0.1em;
  color: #888888;
}

.gc-notification {
  font-family: Arial, serif;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 10px 5px 0;
  letter-spacing: 0.1em;
  color: #888888;

  & a.gc-link {
    color: #888888;
  }
}

.gc-preloader {
  position: fixed;
  z-index: 6405;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);

  &:after {
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    content: " ";
    animation: gc-preloader 1.2s linear infinite;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    border-radius: 50%;
  }

  &.gc-hidden {
    display: none;
  }
}

@keyframes gc-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}